import React, { useEffect, useState, useContext } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { startKitchenOrders } from '../store/actions/kitchenAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { startBaristaOrders } from '../store/actions/baristaAction';
import { SocketContext } from '../context/socket.context';

const Stopwatch = ({ OrderId, offset, started, setStarted, phone, kitLen, barLen, pickTime, orderFrom }) => {
  const socket = useContext(SocketContext)
  const [autoStart, setAutostart] = useState(false);
  const [startOrderFlag, setStartOrderFlag] = useState(false);
  const page = useSelector(state => state.page)
  const [offsetInSeconds, setOffsetInSeconds] = useState(0);
  const employee_info = useSelector(state => state.emp_info);
  const kitchen = useSelector(state => state.kitchen);
  const barista = useSelector(state => state.barista);
  const dispatch = useDispatch();
  let currDate = new Date();

  useEffect(() => {
    if (started) {
      console.log('autostart stopwatch')
      setAutostart(true);
      const diff = Math.abs(((currDate.getTime()) - offset) / 1000);
      setOffsetInSeconds(diff);
    }
  }, [phone, kitchen, barista]);
  function MyStopwatch() {

    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(
      stopwatchOffset.getSeconds() + (offsetInSeconds ?? 0)
    );

    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      reset,
    } = useStopwatch({ autoStart: autoStart, offsetTimestamp: stopwatchOffset });

    // useEffect(() => {
    //   // console.log('Inside stopwatch socket change' + OrderId);
    //   socket.on('start_order_success', (order)=>{
    //     console.log('start_order_success', started);

    //     if((order.order_id == OrderId)){
    //       console.log('start_order_success', order);
    //       setStarted(true);
    //       setAutostart(true);
    //       toast.success(`Timer for Order ${OrderId} started`, {
    //         position: "top-right",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored"
    //       })
    //       start()
    //     }
        
    //     return () => {
    //       socket.off("start_order_success", ()=>{
    //         console.log("start_order_success offed")
    //       });
    //     }
        
    //   })
    // }, [socket]);
    const formatAMPM = (date)=> {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }

    useEffect(()=>{
      if(started){
        start()
      }
    }, [started])

    useEffect(()=>{
      if(pickTime !== null){
        var interval = setInterval(()=>{
          const currentTime = Date.now()
          var pickupTime = new Date(pickTime)
          pickupTime.setMinutes(pickupTime.getMinutes() - 30)
          // console.log('i am in interval')
          if(currentTime > pickupTime.getTime()){
            // Order now could be started
            console.log(OrderId, 'Order now could be started')
            setStartOrderFlag(true)
            clearInterval(interval)
          }
        }, 1000)
      }
      else{
        setStartOrderFlag(true)
      }
      return ()=>{
        if(interval){
          clearInterval(interval)
        }
      }
    }, [])

    const onClickAction = () => {
      setOffsetInSeconds(0);
      const currentTime = Date.now()
      if (employee_info.role === "kitchen") {
        if ((kitLen > 0) && (pickTime !== null) && (pickTime !== undefined)){
          // website or app order for pickup
          console.log('pickTime', pickTime)
          var pickupTime = new Date(pickTime)
          console.log('pickupTime', formatAMPM(pickupTime))
          pickupTime.setMinutes(pickupTime.getMinutes() - 30)
          if(currentTime > pickupTime.getTime()){
            // Order now could be started
            console.log(OrderId, 'Order now could be started')
            setStarted(true);
            setAutostart(true);
            start()
            socket.emit("start_order", {
              barista: (barLen > 0) ? true : false,
              branch: employee_info.employeeinfo.data.branch_id,
              data: {
                order_id: OrderId,
                "paid": true,
                "status": "Preparing",
                "completed_time": null,
                "start_time": currentTime
              }
            })
          }else{
            // order could not be started
            toast.error(`You could start this order after ${formatAMPM(pickupTime)} ` , {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          }
        }
        else if (kitLen > 0) {
          // dispatch(startKitchenOrders(page, OrderId, currentTime, employee_info.employeeinfo.data.branch_id, employee_info.employeeinfo.data.token, employee_info.role))
          setStarted(true);
          setAutostart(true);
          // toast.success(`Timer for Order ${OrderId} started`, {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored"
          // })
          start()

          
          // todo: the above rules with socket
          socket.emit("start_order", {
            barista: (barLen > 0) ? true : false,
            branch: employee_info.employeeinfo.data.branch_id,
            data: {
              order_id: OrderId,
              "paid": true,
              "status": "Preparing",
              "completed_time": null,
              "start_time": currentTime
            }
          })
        }
        else {
          toast.error(`You are not authorized to start this order`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
          })
        }
      }
      else {
        if ((barLen > 0) && (pickTime !== null) && (pickTime !== undefined)){
          // website or app order for pickup
          console.log('pickTime', pickTime)
          var pickupTime = new Date(pickTime)
          console.log('pickupTime', formatAMPM(pickupTime))
          pickupTime.setMinutes(pickupTime.getMinutes() - 30)
          if(currentTime > pickupTime.getTime()){
            // Order now could be started
            console.log(OrderId, 'Order now could be started')
            setStarted(true);
            setAutostart(true);
            start()
            socket.emit("start_order", {
              kitchen: (kitLen > 0) ? true : false,
              branch: employee_info.employeeinfo.data.branch_id,
              data: {
                order_id: OrderId,
                "paid": true,
                "status": "Preparing",
                "completed_time": null,
                "start_time": currentTime
              }
            })
          }else{
            // order could not be started
            toast.error(`You could start this order after ${formatAMPM(pickupTime)} ` , {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          }
        }
        else if (barLen > 0) {
          setStarted(true);
          setAutostart(true);
          // toast.success(`Timer for Order ${OrderId} started`, {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored"
          // })
          start()
          // dispatch(startBaristaOrders(page, OrderId, currentTime, employee_info.employeeinfo.data.branch_id, employee_info.employeeinfo.data.token, employee_info.role))
          // todo: the above rules with socket
          socket.emit("start_order", {
            kitchen: (kitLen > 0) ? true : false,
            branch: employee_info.employeeinfo.data.branch_id,
            data: {
              order_id: OrderId,
              "paid": true,
              "status": "Preparing",
              "completed_time": null,
              "start_time": currentTime
            }
          })
        }
        else {
          toast.error(`You are not authorized to start this order`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
          })
        }

      }
      // { employee_info.role === "kitchen" ? dispatch(startKitchenOrders(page, OrderId, currentTime, employee_info.employeeinfo.data.branch_id, employee_info.employeeinfo.data.token, employee_info.role)) : dispatch(startBaristaOrders(page, OrderId, currentTime, employee_info.employeeinfo.data.branch_id, employee_info.employeeinfo.data.token, employee_info.role)) };

    }
    return (

      <div style={{ display: "inline" }} >
        {started ? (<>Preparing:<span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span></>) : <div className="btn btn-sm btn-success start-order" onClick={() => onClickAction()} style={startOrderFlag ? {opacity: '1'} :{opacity: '0.5'}}>Start Order</div>}

      </div>


    );
  }


  return (

    <MyStopwatch OrderId={OrderId} offset={offset} />

  );
}
export default Stopwatch;